import React from 'react';
import Box from "@mui/material/Box";

const Contact = () => {
    return (
        <Box>
            <h2>Contact Us</h2>
            <p>
                info@NourishmentOnDemand.com
            </p>
        </Box>
    );
};

export default Contact;
